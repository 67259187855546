<template>
    <div class="embed-live-preview relative h-full">
        <ImageOptimized v-if="showPoster" :src="poster" class="absolute z-10 h-full w-full object-cover" alt="" />
        <video
            ref="video"
            id="amazon-ivs-videojs"
            class="video-js vjs-skin vjs-big-play-centered"
            crossorigin="anonymous"
            :autoplay="autoplay"
            :muted="muted"
            playsinline
        ></video>
    </div>
</template>

<script>
export default {
    name: 'EmbedLivePreview',
    props: {
        playbackUrl: {
            required: true,
            type: String,
            default: null,
        },
        playbackSrcDelay: {
            required: false,
            type: Number,
            default: 100,
        },
        autoplay: {
            required: false,
            type: [Boolean, Number],
            default: true,
        },
        muted: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
        hideBigPlayButton: {
            required: false,
            type: [Boolean, Number],
            default: true,
        },
        hideControlBar: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
    },
    data () {
        return {
            player: null,
            error: null,
            poster: this.$config.public.images + 'live-stream-loading.jpg',
            showPoster: true
        };
    },
    methods: {
        async plugins () {
            const videojs = usePluginVideo();
            await videojs.init();
            const ivs = usePluginIvs();
            await ivs.init();
        },
        async init () {
            await this.plugins();
            // https://docs.aws.amazon.com/ivs/latest/LowLatencyUserGuide/player-videojs.html
            this.$registerIVSTech(this.$videojs, {
                wasmWorker: '/assets/ivs/amazon-ivs-wasmworker.min.js',
                wasmBinary: '/assets/ivs/amazon-ivs-wasmworker.min.wasm',
            });
            const element = this.$refs.video;
            const player = this.$videojs(element, {
                muted: this.muted,
                techOrder: ['AmazonIVS'],
                bigPlayButton: !this.hideBigPlayButton,
                controlBar: this.hideControlBar ? false : {
                    pictureInPictureToggle: false,
                },
            });
            player.on('playing', () => {
                this.showPoster = false;
            });
            setTimeout(() => {
                player.src(this.playbackUrl);
            }, this.playbackSrcDelay);
            player.on('error', this.playerError);
            player.on('ended', this.playerError);
            let playerEvent = player.getIVSEvents().PlayerEventType;
            player.getIVSPlayer().addEventListener(playerEvent.ERROR, this.ivsError);
            this.player = player;
        },
        dispose () {
            if (this.player && this.player.dispose) {
                this.player.dispose();
                this.player = null;
            }
        },
        playerError (error) {
            console.log('PLAYER ERROR', error);
            this.handleError();
        },
        ivsError (error) {
            console.log('IVS ERROR', error);
            this.handleError();
        },
        handleError () {
            this.poster = this.$config.public.images + 'live-stream-offline.jpg';
            this.showPoster = true;
            setTimeout(() => {
                this.connect();
            }, 10000);
        },
        connect () {
            if (!this.player) return this.init();
            this.player.src(this.playbackUrl);
        },
    },
    mounted () {
        this.init();
    },
    beforeUnmount () {
        this.dispose();
    },
};
</script>
