<template>
    <section class="section-stream-jumbo" :class="color">
        <div class="relative isolate overflow-hidden">
            <div class="absolute inset-0 -z-10" v-if="stream?.stream_playback_url?.includes('token')">
                <EmbedLivePreview
                    class="absolute inset-0 w-full h-full opacity-40"
                    :playbackUrl="stream.stream_playback_url"
                    :muted="true"
                ></EmbedLivePreview>
            </div>
            <ImageJumbo
                class="-z-20"
                v-else
                :image="stream.stream_image_url"
                imageAlt="A preview of a member only live stream"
                imagePosition="object-center"
                imageOpacity="opacity-30"
                :imageLoading="imageLoading"
                :fetchPriority="fetchPriority"
            ></ImageJumbo>
            <LayoutContained :class="innerClasses" class="z-0">
                <ContentHeadline
                    v-if="heading"
                    :heading="heading"
                    :headingColor="headingColor"
                    :headingComponent="headingComponent"
                    :marginBottom="content ? 'mb-6' : 'mb-0'"
                ></ContentHeadline>
                <ContentText
                    v-if="content"
                    :html="content"
                    :contentColor="contentColor"
                ></ContentText>
                <slot name="customTwo"></slot>
                <div v-if="buttonText" class="mt-8">
                    <ButtonPrimary
                        :buttonColor="buttonColor || undefined"
                        :buttonRoute="buttonRoute"
                        :buttonHref="buttonHref"
                        :buttonTarget="buttonTarget"
                        :buttonText="buttonText"
                        :buttonIcon="buttonIcon"
                        :buttonClick="buttonClick"
                        :buttonClickParams="buttonClickParams"
                    ></ButtonPrimary>
                </div>
                <slot name="custom"></slot>
            </LayoutContained>
        </div>
    </section>
</template>

<script>
export default {
    name: 'SectionStreamJumbo',
    props: {
        stream: {
            required: false,
            type: Object,
            default () {
                return {};
            },
        },
        padding: {
            required: false,
            default: 'py-20 sm:py-24 lg:py-32',
            type: String,
        },
        buttonColor: {
            required: false,
            type: String,
            default: null,
        },
        buttonHref: {
            required: false,
            type: String,
        },
        buttonTarget: {
            required: false,
            type: String,
            default: null,
        },
        buttonIcon: {
            required: false,
            type: String,
            default: null,
        },
        buttonClick: {
            required: false,
            type: Function,
            default: () => {},
        },
        buttonClickParams: {
            required: false,
            type: [String, Object, Array],
            default: null,
        },
        color: {
            required: false,
            type: String,
            default: 'bg-zinc-900',
        },
        contentColor: {
            required: false,
            type: String,
            default: 'text-zinc-100',
        },
        headingColor: {
            required: false,
            type: String,
            default: 'text-white',
        },
        headingComponent: {
            required: false,
            type: String,
            default: 'h1',
        },
        imageLoading: {
            required: false,
            type: String,
            default: undefined,
        },
        fetchPriority: {
            required: false,
            type: [String, undefined],
            default: undefined,
        },
    },
    computed: {
        innerClasses () {
            const cls = {};
            cls[this.padding] = true;
            return cls;
        },
        heading () {
            return this.stream.stream_title;
        },
        content () {
            if (this?.stream?.stream_starts > new Date()) return this?.stream?.stream_time_from_now;
            return '<p><strong>LIVE NOW:</strong> ' + this.stream.stream_excerpt + '</p>';
        },
        buttonText () {
            return 'Watch Live';
        },
        buttonRoute () {
            return this.stream.stream_route;
        },
    },
};
</script>
